import React, { useEffect, useRef } from "react";
import "./SwitchLottie.scss";
import overstappenLottie from "../../../resources/lottie/overstappen.json";
import Lottie from "react-lottie-player";

interface IProps {
}

export function SwitchLottie(props: IProps) {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            const wrapper = ref.current as any as HTMLDivElement;
            const width = wrapper.clientWidth;
            const height = width * 0.555;
            wrapper.style.height = `${height}px`;
        }
    }, [ref]);

    return (
        <div className="switch-lottie-wrapper">
            <div className="animation-wrapper" ref={ref} style={{overflow: 'hidden'}}>
                <Lottie
                    loop
                    animationData={overstappenLottie}
                    play
                    style={{ width: '90%' }}
                />
            </div>
            <div className="description-wrapper">
                <h4>Snelle overstapservice</h4>
                <h4>Gratis online consult</h4>
                <h4>Voordelige data import</h4>
            </div>
        </div>
    )
}
